import { Controller } from "stimulus"

const GOOGLE_KG_API_KEY = 'AIzaSyDHJT2wDZuHPDj00Ahk1SGPRExVM99i6VU'

// https://schema.org/CreativeWork
// https://schema.org/docs/full.html
const GOOGLE_KG_AVAILABLE_TYPES_AS_HOST = [
  'CreativeWork', 'CreativeWorkSeries',
  'Book', 'AudioBook', 'BookSeries',
  'Movie', 'MovieSeries',
  'TVSeries', 'TVSeason', 'TVEpisode',
  'Game', 'VideoGame', 'VideoGameSeries',
  'ComicStory', 'ComicSeries',
  'MusicComposition', 'MusicPlaylist', 'MusicAlbum', 'MusicRelease', 'MusicRecording',
  'RadioSeries', 'PodcastSeries'
]

const GOOGLE_KG_AVAILABLE_TYPES_AS_TARGET = GOOGLE_KG_AVAILABLE_TYPES_AS_HOST.concat([
  'Person',
  'PerformingGroup', 'DanceGroup', 'MusicGroup', 'TheaterGroup',
  'SportsOrganization', 'SportsTeam',
  'Event', 'BusinessEvent', 'ChildrensEvent', 'ComedyEvent', 'CourseInstance', 'DanceEvent', 'DeliveryEvent', 'EducationEvent', 'EventSeries', 'ExhibitionEvent', 'Festival', 'FoodEvent', 'Hackathon', 'LiteraryEvent', 'MusicEvent', 'PublicationEvent', 'BroadcastEvent', 'OnDemandEvent', 'SaleEvent', 'ScreeningEvent', 'SocialEvent', 'SportsEvent', 'TheaterEvent', 'VisualArtsEvent',
])

function truncateStr(str, length) {
  return str.length > length ? str.slice(0, length) + '…' : str
}

async function queryGoogleKG(query) {
  if (query == '') { return [] }

  let url = new URL('https://kgsearch.googleapis.com/v1/entities:search')
  url.search = new URLSearchParams({
    key: GOOGLE_KG_API_KEY,
    types: GOOGLE_KG_AVAILABLE_TYPES_AS_TARGET,
    query: query,
    limit: 8
  })

  let response = await fetch(url.toString())
  if (!response.ok) { return [] }
  let body = await response.json()
  let entities = body.itemListElement.map(function(item){
    let entity = item.result

    let id = entity['@id']
    let name = entity.name
    let description = entity?.description || entity?.detailedDescription?.articleBody
    let imageUrl = entity?.image?.contentUrl

    return {
      id: id,
      name: name,
      description: description,
      imageUrl: imageUrl,
    }
  })

  return entities
}

export default class extends Controller {
  connect() {
    console.log('refs--create: connect')

    this.initHostInput()
    this.initContentInput()
  }

  initHostInput() {
    KGSearchWidget(
      GOOGLE_KG_API_KEY,
      document.getElementById('ref_host_name'),
      {
        limit: 8,
        types: GOOGLE_KG_AVAILABLE_TYPES_AS_HOST,
        selectHandler: function(event) {
          let record = event.row.json
          document.querySelector('input#ref_host_google_kg_id').value = record['@id']
        }
      }
    )
  }

  initContentInput() {
    let tribute = new Tribute({
      values: async function (text, cb) {
        cb(await queryGoogleKG(text))
      },
      searchOpts: {
        // true will skip local search, useful if doing server-side search
        skip: true
      },
      // requireLeadingSpace: false,
      allowSpaces: true,

      // template for displaying item in menu
      menuItemTemplate: function (item) {
        let entity = item.original

        let img = entity.imageUrl ? `<img src=${entity.imageUrl} width=32 height=32 style="object-fit: contain">` : ''
        let name = `<strong>${entity.name}</strong>`
        let desc = entity.description ? `<small>${truncateStr(entity.description, 120)}</small>` : ''

        return `
          ${img}
          ${name}
          ${desc}
        `
      },

      noMatchTemplate: () => '',

      // function called on select that returns the content to insert
      selectTemplate: function (item) {
        let entity = item.original

        return `{{${entity.name}|${entity.id}}}`
      },
    })
    tribute.attach(document.getElementById('ref_content'))
  }
}
